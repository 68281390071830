import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { CelumDialog } from '../../model/celum-dialog';
import { StandardDialogConfiguration } from '../../model/standard-dialog-configuration';
import { buttonType } from '../base-dialog/dialog-footer';

@Component({
             selector: 'celum-standard-dialog',
             templateUrl: './standard-dialog.html',
             encapsulation: ViewEncapsulation.None,
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class StandardDialog implements CelumDialog<StandardDialogConfiguration> {

  @Input() public color: string;
  @Input() public cancelBtnText = 'COMMON.CANCEL';
  @Input() public confirmBtnText = 'COMMON.OK';
  @Input() public buttons: buttonType[] = ['ok', 'cancel'];
  @Input() public disableOnCancelClosing = false;
  @Input() public disableOnConfirmClosing = false;
  @Input() public disableOnEnterConfirmation = false;
  @Input() public headerText = '';
  @Input() public message = '';
  @Input() public valid = true;
  @Input() public errors: any[] = [];
  @Input() public loading = false;
  // if true, prevents from having button able to close all opened dialogs
  @Input() public hideCloseAllBtn = false;

  @Output() public readonly onCancel = new EventEmitter();
  @Output() public readonly onCloseAll = new EventEmitter();
  @Output() public readonly onConfirm = new EventEmitter();

  constructor(private dialogRef: MatDialogRef<StandardDialog>) {
  }

  public cancel(event: any): void {
    this.onCancel.emit(event);
    if (!this.disableOnCancelClosing) {
      this.dialogRef.close(false);
    }
  }

  public closeAll(event: any): void {
    this.onCloseAll.emit(event);
    if (!this.disableOnCancelClosing) {
      this.dialogRef.close(false);
    }
  }

  public confirm(event: any): void {
    this.onConfirm.emit(event);
    if (!this.disableOnConfirmClosing) {
      this.dialogRef.close(true);
    }
  }

  public configure(config: StandardDialogConfiguration): void {
    const { cancelBtnText, confirmBtnText, color, headerText, message } = config;
    this.headerText = headerText;
    this.message = message;
    cancelBtnText && (this.cancelBtnText = cancelBtnText);
    confirmBtnText && (this.confirmBtnText = confirmBtnText);
    color && (this.color = color);
  }
}
