import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MessageDialogConfiguration } from './message-dialog-config';
import { MessageConfiguration } from '../../../message/message-configuration';
import { CelumDialog } from '../../model/celum-dialog';

@Component({
             selector: 'celum-message-dialog',
             templateUrl: './message-dialog.html',
             styleUrls: ['./message-dialog.less'],
             encapsulation: ViewEncapsulation.None,
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class MessageDialog implements CelumDialog<MessageDialogConfiguration>, OnInit {

  @Input() public color: string;
  @Input() public config: MessageDialogConfiguration;
  @Input() public valid = true;

  public messageConfig: MessageConfiguration;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private data: MessageDialogConfiguration) {
    if (this.data) {
      this.configure(this.data);
    }
  }

  public configure(config: MessageDialogConfiguration): void {
    this.config = config;
    this.messageConfig = MessageDialog.evaluateMessageConfig(config);
    this.setColor();
  }

  public ngOnInit(): void {
    this.configure(this.config);
  }

  public onCancelClick(): void {
    this.config.cancelBtnFunction?.();
  }

  public onConfirmClick(): void {
    this.config.okBtnFunction?.();
  }

  private setColor(): void {
    // if the config specifically defines a color, overwrite it!
    if (this.config.color) {
      this.color = this.config.color;
    }
  }

  private static evaluateMessageConfig(config: MessageDialogConfiguration): MessageConfiguration {
    let msgConfig = config.dialogType === 'info' ? MessageConfiguration.info(config.title) :
                    config.dialogType === 'warning' ? MessageConfiguration.warn(config.title) :
                    MessageConfiguration.error(config.title);

    if (config.icon) {
      msgConfig = msgConfig.withIcon(config.icon);
    }

    if (config.color) {
      msgConfig = msgConfig.withColor(config.color);
    }

    msgConfig = msgConfig.withMessages(config.messages);
    msgConfig.withMessageParams(config.messageParams);
    msgConfig.withTitleParams(config.titleParams);
    return msgConfig;
  }
}
