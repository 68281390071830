import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, shareReplay, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CelumPropertiesProvider } from '@celum/core';

import { AuthService } from './auth.service';
import { AuthToken, ServiceTokenRequestDto } from '../model/auth-token.model';

export interface TokenCache {
  localStorageKey: string;
  cachedTokens: any;
  dtoHash: number;
  cachedToken: any;
}

export const TOKEN_REFRESH_LEAD_TIME_IN_MS = 1000 * 60; // 1 minute

@Injectable()
export class ServiceAccessTokenProvider {
  private readonly TOKEN_KEY = 'Celum_Service_AccessTokens';
  private tokenRequestsInProgress = new Map<number, Observable<string>>();

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  public getServiceAccessToken<T extends ServiceTokenRequestDto>(requestDto: T): Observable<string> {
    if (!requestDto) {
      return throwError(() => new Error('No ServiceAccessTokenDto provided'));
    }

    const { cachedTokens, dtoHash, localStorageKey, cachedToken } = this.getCachedToken(requestDto);

    if (ServiceAccessTokenProvider.isStillValid(new Date().getTime(), cachedToken?.expiresAt, TOKEN_REFRESH_LEAD_TIME_IN_MS)) {
      return of(cachedToken.token);
    }

    if (!this.tokenRequestsInProgress.has(dtoHash)) {
      this.tokenRequestsInProgress.set(
        dtoHash,
        this.http.post<AuthToken>(`${CelumPropertiesProvider.properties.authentication.saccUrl}/auth/token`, requestDto).pipe(
          map(serviceAccessToken => {
            cachedTokens[dtoHash] = serviceAccessToken;
            localStorage.setItem(localStorageKey, JSON.stringify(cachedTokens));
            this.tokenRequestsInProgress.delete(dtoHash);
            return serviceAccessToken.token;
          }),
          catchError(error => {
            console.error('Something went wrong during acquiring the service access token.', error);
            this.tokenRequestsInProgress.delete(dtoHash);
            return of('');
          }),
          shareReplay(1)
        )
      );
    }

    return this.tokenRequestsInProgress.get(dtoHash);
  }

  /**
   * Creates a simple 32-bit has. Taken from https://stackoverflow.com/a/7616484
   * @param input
   * @protected
   */
  protected hashCode(input: string): number {
    let hash = 0;
    for (let i = 0, len = input.length; i < len; i++) {
      const chr = input.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  private getCachedToken<T extends ServiceTokenRequestDto>(requestDto: T): TokenCache {
    const localStorageKey = `${this.TOKEN_KEY}_${this.authService.getMsalAccount().homeAccountId}_${requestDto.clientId}`;
    const localStorageCachedTokens = localStorage.getItem(localStorageKey);
    const cachedTokens = JSON.parse(localStorageCachedTokens) || {};
    const dtoHash = this.hashCode(JSON.stringify(requestDto));
    const cachedToken = cachedTokens[dtoHash];
    return {
      localStorageKey,
      cachedTokens,
      dtoHash,
      cachedToken
    };
  }

  /**
   * Checks if the provided expiration date is still valid considering a lead time.
   *
   * @param now - current timestamp
   * @param expiresAt - ISO formatted date string
   * @param leadTimeInMs - the lead time in milliseconds
   *
   * @returns whether the expiration date is still valid
   */
  private static isStillValid(now: number, expiresAt: string, leadTimeInMs: number): boolean {
    if (!expiresAt) {
      return false;
    }

    return new Date(expiresAt).getTime() - leadTimeInMs > now;
  }
}
